import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	mapContainer: {
		width: "100%",
		height: "100vh",
		paddingTop: 30,
		paddingBottom: 100,
		paddingLeft: 30,
		paddingRight: 20
	},
	resultsContainer: {
		paddingTop: 30,
		paddingBottom: 5,
		paddingLeft: 10,
		paddingRight: 20
	},
	dashboardParent: {
		overflowY: 'hidden',
		width: '100%',
		whiteSpace: 'pre-wrap'
	},
	dashboardResults: {
		display: 'flex',
		flexDirection: 'column'
	},
	selectContainer: {
		paddingTop: 10,
		paddingBottom: 15,
		paddingLeft: 10,
		paddingRight: 25,
	},
	btnPlay: {
		padding: 15
	},
	sensorLogContainer: {
		marginTop: 15
	},
	sensorLogElement: {
		display: 'flex',
		flexDirection: 'row',
		marginBottom: 15,
		paddingLeft: 10,
		paddingRight: 10,
	},
	sensorLogResults: {
		height: 180,
		overflowY: 'scroll',
		paddingTop: 15,
		paddingBottom: 15,
		paddingLeft: 10,
		border: '1px solid #ddd',
		paddingRight: 50
	},
	eventQueueResults: {
		height: 180,
		overflowY: 'scroll',
		paddingTop: 15,
		paddingBottom: 15,
		paddingLeft: 10,
		border: '1px solid #ddd',
		paddingRight: 50
	},
	title: {
		marginBottom: 10
	},
	formControlLabel: {
		paddingLeft: 10,
	},
	buttonGroup: {
		float: 'right',
		'& > button:first': {
			flex: 1
		}
	},
	paper: {
		width: '100%'
	},
	btnClear: {
		marginBottom: theme.spacing(1.5)
	}
}));

export default useStyles;