import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { io } from "socket.io-client";
import DashboardResults from './components/DashboardResults';
import useStyles from './styles/Dashboard-jss';

// configs
import environment from '../../configs/environment';

const Dashboard = () => {
  const classes = useStyles();
  const [socket, setSocket] = useState<any>(null);

  useEffect(() => {
    const socketIO = io(environment.SIGNALING, { secure: true, rejectUnauthorized: false, transports : ['websocket'] });
    setSocket(socketIO);
    return () => {
      socketIO.close();
    };
  }, [setSocket]);

  return (
    <React.Fragment>
      <Grid container spacing={3} className={classes.dashboardParent}>
        <DashboardResults socket={socket} />
      </Grid>
    </React.Fragment>
  );
};
export default Dashboard;