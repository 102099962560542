import Api from '../Api/Api';

export type TLocation = {
  latitude: number;
  longitude: number;
};

export type TTrackerReponseValues = {
  tracker_id: string;
  external_id: string;
  name: string;
  created_at: string;
  updated_at: string;
};
export type TSensorLogResponseValues = {
  id: number;
  tracker_id: string;
  latitude: string;
  longitude: string;
  temperature: number;
  recorded_date: string;
  additional_data: object | null;
  additional_markers_data?: TSensorLogResponseValues[]
  created_at: string;
  updated_at: string;
};
export type TEventsResponseValues = {
  id: number;
  tracker_id: string;
  key: string;
  description: string | null;
  created_at: string;
  updated_at: string;
};

export type TEventsRequestData = {
  key: string;
  description: string;
};

export type TTrackerConfigResponseValues = {
  tracker_id: string;
  frequency: number;
  frequency_unit: string;
};

export type TTrackerConfigRequestData = {
  frequency: number;
  frequency_unit: string;
};

const Tracker = () => {
  const getUserTrackers = async () => {
    return await Api.initialize().get('/user/trackers');
  };

  const getLatestTrackerData = async (trackerId: string, trackerDataId: number) => {
    return await Api.initialize().get(`/trackers/${trackerId}/latest/${trackerDataId}`);
  };

  const getNewTrackerData = async (trackerId: string, trackerDataId: number) => {
    return await Api.initialize().get(`/trackers/${trackerId}/data/${trackerDataId}`);
  };

  const deleteTrackerData = async (trackerId: string) => {
    return await Api.initialize().delete(`/trackers/${trackerId}/data`);
  };

  const getTrackerEvents = async (trackerId: string) => {
    return await Api.initialize().get(`/trackers/${trackerId}/events`);
  };

  const createTrackerEvents = async (trackerId: string, formData: TEventsRequestData) => {
    return await Api.initialize().post(`/trackers/${trackerId}/events`, formData);
  };

  const deleteTrackerEvents = async (trackerId: string) => {
    return await Api.initialize().delete(`/trackers/${trackerId}/events`);
  };

  const getTrackerConfig = async (trackerId: string) => {
    return await Api.initialize().get(`/trackers/${trackerId}/config`);
  };

  const createTrackerConfig = async (trackerId: string, formData: TTrackerConfigRequestData) => {
    return await Api.initialize().post(`/trackers/${trackerId}/config`, formData);
  };

  const updateTrackerConfig = async (trackerId: string, formData: TTrackerConfigRequestData) => {
    return await Api.initialize().put(`/trackers/${trackerId}/config`, formData);
  };

  return {
    getUserTrackers,
    getLatestTrackerData,
    deleteTrackerData,
    getNewTrackerData,
    getTrackerEvents,
    createTrackerEvents,
    deleteTrackerEvents,
    getTrackerConfig,
    createTrackerConfig,
    updateTrackerConfig
  };
};

export default Tracker;