import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { Socket } from 'socket.io-client';

// MUI
import {
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Button,
  Typography,
  Box,
  Paper
 } from '@material-ui/core';

// Styles
import useStyles from '../styles/Dashboard-jss';

// Auth Hook
import AuthHook from '../../../services/Hooks/Auth';

// Child Components
import GoogleMap from './GoogleMap';

// Services
import TrackerHook, {
  TEventsRequestData,
  TEventsResponseValues,
  TSensorLogResponseValues,
  TTrackerReponseValues,
  TTrackerConfigResponseValues
} from '../../../services/Hooks/Tracker';

type TFormDataValues = {
  defaultRate: number,
  deviceId: string,
  lengthOfMinute: number
};

interface IProps {
  socket: Socket
};

const rangeValues: Array<number> = [1, 5, 10, 15];
const showMarkers: Array<number> = [1, 4, 7, 10];

const DashboardResults: React.FC<IProps> = ({ socket }) => {
  const classes = useStyles();
  const [devices, setDevices] = useState<TTrackerReponseValues[]>([]);
  const [trackerData, setTrackerData] = useState<TSensorLogResponseValues[]>([]);
  const [trackerEvents, setTrackerEvents] = useState<TEventsResponseValues[]>([]);
  const [trackerLogs, setTrackerLogs] = useState<TSensorLogResponseValues[]>([]);
  const [trackerConfig, setTrackerConfig] = useState<TTrackerConfigResponseValues | null>(null);
  const [formData, setFormData] = useState<TFormDataValues>({
    defaultRate: 1,
    deviceId: '',
    lengthOfMinute: 1
  });
  const authHook = AuthHook();
  const trackerHook = TrackerHook();

  const compare = (objectOne: TSensorLogResponseValues, objectTwo: TSensorLogResponseValues) => {
    let isCompared = 0;
    if (objectOne.id > objectTwo.id) {
      isCompared = -1;
    } else if (objectOne.id < objectTwo.id) {
      isCompared = 1;
    }
    return isCompared;
  }
  const unique = (arrOne: TSensorLogResponseValues[]) => {
    const result = [arrOne[0]];
    for (var i = 1; i < arrOne.length; i++) { //Start loop at 1: arr[0] can never be a duplicate
      if (arrOne[i-1].id !== arrOne[i].id) {
        result.push(arrOne[i]);
      }
    }
    return result;
  };

  const getLatestTrackerData = useCallback(async (deviceId: string, trackerDataId: number, currentTrackerData: TSensorLogResponseValues[]) => {
    const trackerResp = await trackerHook.getLatestTrackerData(deviceId, trackerDataId);
    if (trackerResp.data !== undefined && trackerResp.data.status === 'success') {
      let newTrackerLogs = [...currentTrackerData].concat(trackerResp.data.data);
      newTrackerLogs.sort(compare);
      newTrackerLogs = unique(newTrackerLogs);
      addLogsToList(newTrackerLogs, true);

      if (currentTrackerData[currentTrackerData.length - 1] !== undefined && currentTrackerData[currentTrackerData.length - 1].additional_markers_data === undefined) {
        currentTrackerData[currentTrackerData.length - 1].additional_markers_data = trackerResp.data.data;
        setTrackerData(currentTrackerData);
      }
    }
  }, [trackerHook])

  const getTrackerEvents = async (deviceId: string) => {
    const eventsResp = await trackerHook.getTrackerEvents(deviceId);
    if (eventsResp.data !== undefined && eventsResp.data.status === 'success') {
      setTrackerEvents(eventsResp.data.data);
    }
  };

  const getTrackerConfig = async (deviceId: string) => {
    const trackerConfig = await trackerHook.getTrackerConfig(deviceId);
    if (trackerConfig.data !== undefined && trackerConfig.data.status === 'success' && Object.keys(trackerConfig.data.data).length > 0) {
      setTrackerConfig(trackerConfig.data.data);
    }
  };
  const getRate = useCallback(() => {
    return (trackerConfig && trackerConfig.frequency) || formData.defaultRate
  }, [trackerConfig, formData.defaultRate])

  const composeRateTemplate = (newInterval: number, oldInterval: number) => {
    let content = 'Increase rate';
    if (newInterval < oldInterval) {
      content = 'Decrease rate';
    }
    content += ` ${oldInterval} min --> ${newInterval} min`;
    return content;
  };
  const createTrackerEvent = useCallback(async (deviceId: string, formData: TEventsRequestData) => {
    const event = await trackerHook.createTrackerEvents(deviceId, formData);
    if (event.data !== undefined && event.data.status === 'success') {
      setTrackerEvents((prevState: TEventsResponseValues[]) => {
        let newState = prevState;
        if (newState.length > 0) {
          newState.unshift(event.data.data);
        } else {
          newState.push(event.data.data);
        }
        return [...newState];
      });
    }
  }, [trackerHook])

  const addLogsToList = (newLogs: any, multiple:boolean = false) => {
    if (!multiple) {
      setTrackerLogs((prevLogs) => {
        if (prevLogs.length > 0) {
          prevLogs.unshift(newLogs);
        } else {
          prevLogs.push(newLogs);
        }
        return [...prevLogs];
      });
    } else {
      setTrackerLogs(newLogs);
    }
  };

  useEffect(() => {
    trackerHook.getUserTrackers().then((response) => {
      if (response.data !== undefined && response.data.status === 'success') {
        setDevices(response.data.data);
        if (response.data.data.length > 0) {
          trackerHook.deleteTrackerData(response.data.data[0].tracker_id);
          setFormData({ ...formData, deviceId: response.data.data[0].tracker_id });
          getTrackerEvents(response.data.data[0].tracker_id);
          getTrackerConfig(response.data.data[0].tracker_id);
        }
      }
    });
  }, []);

  useEffect(() => {
    const getNewTrackerData = async (trackerId: string, trackerDataId: number) => {
      const response = await trackerHook.getNewTrackerData(trackerId, trackerDataId);
      if (response.data !== undefined && response.data.status === 'success') {
        const newTrackerData = trackerData.concat(response.data.data);
        setTrackerData(newTrackerData);
        if (response.data.data.temperature >= 90 && getRate() > 1) {
          getLatestTrackerData(trackerId, trackerDataId, newTrackerData);
          createTrackerEvent(formData.deviceId, {
            key: 'threshold_exceeded',
            description: 'Threshold exceeded, historical data requested from device.'
          });
        } else {
          addLogsToList(response.data.data);
        }
      }
    };
    if (socket) {
      socket.on('new_tracker_data', (message) => {
        const data = JSON.parse(message);
        if (data.trackerId && data.trackerDataId) {
          getNewTrackerData(data.trackerId, data.trackerDataId);
        }
      });
      return () => {
        socket.off('joinTrackerToGroup');
        socket.off('new_tracker_data');
      };
    }
  }, [socket, trackerData, getRate, createTrackerEvent, formData, trackerHook, getLatestTrackerData])

  useEffect(() => {
    if (socket) {
      socket.emit('joinTrackerToGroup', formData.deviceId); //current device id
    }
  }, [formData.deviceId, socket]);

  const handleChange = async (ev: any) => {
    if (ev.target.name === 'deviceId') {
      setFormData({ ...formData, deviceId: ev.target.value });
      getTrackerEvents(ev.target.value);
      getTrackerConfig(ev.target.value);
    }
    if (ev.target.name === 'increaseRate') {
      let resp;
      if (formData.deviceId) {
        if (trackerConfig) {
          resp = await trackerHook.updateTrackerConfig(formData.deviceId, { frequency: ev.target.value, frequency_unit: 'minutes' });
        } else {
          resp = await trackerHook.createTrackerConfig(formData.deviceId, { frequency: ev.target.value, frequency_unit: 'minutes' });
        }
        if (resp.data !== undefined && resp.data.status === 'success') {
          createTrackerEvent(formData.deviceId, {
            key: getRate() > ev.target.value ? 'decrease_rate' : 'increase_rate',
            description: composeRateTemplate(ev.target.value, getRate())
          });
        }
        setTrackerConfig({ ...trackerConfig, frequency: ev.target.value, frequency_unit: 'minutes', tracker_id: formData.deviceId });
      }
    }
  };

  const handleClearTrackerLogs = () => {
    setTrackerLogs([]);
    trackerHook.deleteTrackerData(formData.deviceId);
  };

  const handleClearTrackerEvents = () => {
    setTrackerEvents([]);
    trackerHook.deleteTrackerEvents(formData.deviceId);
  };

  const renderEvents = () => {
    if (!trackerEvents.length) {
      return <Paper className={classes.sensorLogElement}> <Typography variant="subtitle1">No events yet </Typography></Paper>;
    }
    return trackerEvents.map((item: TEventsResponseValues, i:number) => (
      <Paper key={`events-${item.id}`} className={classes.sensorLogElement}>
        <Typography align="right" variant="subtitle1" color="textSecondary">{moment(item.created_at).format('MM/DD/YYYY hh:mm A')}</Typography>
        <Typography variant="subtitle1">{` ${item.description} `}</Typography>
      </Paper>
    ));
  };
  const renderLogText = (trackerData: TSensorLogResponseValues) => {
    let text = '';
    if (trackerData.temperature !== undefined) {
      text += ` Temp ${trackerData.temperature} F`;
    }
    return text;
  };
  const renderLogs = () => {
    if (!trackerLogs.length) {
      return <Paper elevation={3} className={classes.sensorLogElement}><Typography variant="subtitle1">No logs yet </Typography></Paper>;
    }
    return trackerLogs.map((marker: TSensorLogResponseValues, i:number) => {
      return (
        <Paper key={`sensor-logs-${marker.id}`} elevation={3} className={classes.sensorLogElement}>
          {/* <Typography variant="subtitle1">#{marker.id}&nbsp;</Typography> */}
          <Typography color="textSecondary" variant="subtitle1">{moment(marker.created_at).format('MM/DD/YYYY')}</Typography>
          <Typography color="textSecondary" variant="subtitle1">&nbsp;{moment(marker.created_at).format('hh:mm A')}</Typography>
          <Typography variant="subtitle1">{renderLogText(marker)}</Typography>
        </Paper>
      );
    });
  };
  const renderForm = () => (
    <React.Fragment>
      <Grid container spacing={3} className={classes.resultsContainer}>
          <Grid item xs={12} sm={6} md={6}>
            <FormControl fullWidth>
              <InputLabel id="deviceId" className={classes.formControlLabel}>Device ID </InputLabel>
              <Select
                name="deviceId"
                labelId="deviceId"
                id="deviceId"
                value={formData.deviceId}
                onChange={(ev) => handleChange(ev)}
                fullWidth
                variant="filled"
              >
                <MenuItem selected disabled>Select a device</MenuItem>
                {devices.length > 0 && devices.map((device: TTrackerReponseValues) => (
                  <MenuItem key={device.tracker_id} value={device.tracker_id}>{device.tracker_id}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <FormControl fullWidth>
              <InputLabel id="increaseRate" className={classes.formControlLabel}>Reporting Interval</InputLabel>
              <Select
                name="increaseRate"
                labelId="increaseRate"
                id="increaseRate"
                value={getRate()}
                onChange={(ev) => handleChange(ev)}
                fullWidth
                variant="filled"
                disabled={!formData.deviceId}
              >
                {rangeValues.map((val) => (
                  <MenuItem key={`rangevalues-${val}`} value={val}>{`${val}  minute${val > 1 ? 's' : ''}`}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.sensorLogContainer}>
        <Grid item xs={12}>
          <Box display="flex" flexWrap="nowrap">
            <Box flexGrow={1}>
              <Typography variant="h5" className={classes.title}>Sensor Log</Typography>
            </Box>
            <Button className={classes.btnClear} onClick={() => handleClearTrackerLogs()} variant="contained" size="medium">Clear</Button>
          </Box>
          <Box className={classes.sensorLogResults}>
            {renderLogs()}
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.sensorLogContainer}>
        <Grid item xs={12}>
          <Box display="flex" flexWrap="nowrap">
            <Box flexGrow={1}>
              <Typography variant="h5" className={classes.title}>Event Queue</Typography>
            </Box>
            <Button className={classes.btnClear} onClick={() => handleClearTrackerEvents()} variant="contained" size="medium">Clear</Button>
          </Box>
          <Box className={classes.eventQueueResults}>
            {renderEvents()}
          </Box>
        </Grid>
      </Grid>
      <Grid container className={classes.sensorLogContainer}>
        <Grid item xs={12}>
          <Button onClick={() => authHook.logout()} variant="contained" size="large">Log Out</Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <Grid item xs={12} sm={12} md={7}>
        <GoogleMap showMarkers={showMarkers} markers={trackerData || []} />
      </Grid>
      <Grid item xs={12} sm={12} md={5}>
        {renderForm()}
      </Grid>
    </React.Fragment>
  );
};
export default DashboardResults;