import axios, {
  AxiosResponse,
  AxiosError,
  AxiosRequestConfig,
  AxiosInstance
} from 'axios';

// Config
import environment from '../../configs/environment';

import {
  // ApiResponseInterface,
  ApiSettingsInterface,
  ApiHeadersInterface,
  HttpResponseInterface,
  FileInterface
} from './Api.interface';

import { getCookie } from '../../utils/cookie';

// Auth Redux Actions
import { LogoutWithSuccess } from '../../redux/modules/Auth/AuthActions';

// Store
import Store from '../../redux/setup/Store';

// Utils
import alert from '../../utils/alert';

// let isRefreshing = false;
// let failedQueue = [];
let loggingOut = 0;

class Api {
  private static _axios: AxiosInstance;
  private static _baseURI: string;
  private static _version: string = 'v1';

  private static headers(): ApiHeadersInterface {
    const headers: ApiHeadersInterface = {
      Accept: 'application/json',
    };
    const cookie = getCookie('auth');
    if (cookie) {
      headers.Authorization = `Bearer ${cookie}`;
    }
    return headers;
  }

  private static settings(): ApiSettingsInterface {
    return {
      baseURL: `${this._baseURI}/api/${this._version}`,
      withCredentials: true,
      responseType: 'json',
      headers: this.headers(),
      // params: this.defaultAxiosParams(),
      transformResponse: (response: HttpResponseInterface) => response
    };
  }

  protected static transformRequest() {
    this._axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
      if (config.url !== '/sanctum/csrf-cookie') {
        await axios.get(`${this._baseURI}/sanctum/csrf-cookie`);
      }
      return config;
    });
  }

  protected static transformApiResponse() {
    this._axios.interceptors.response.use((response: AxiosResponse) => {
      return response;
    }, (error: AxiosError) => {
      if (error.response !== undefined) {
        switch (error.response.status) {
          case 401:
            if (error.response.data !== undefined && error.response.data.message) {
              alert(error.response.data.message);
            }
            if (!loggingOut) {
              axios.post(`${this._baseURI}/api/${this._version}/logout`).then(() => {
                Store.dispatch(LogoutWithSuccess());
                loggingOut = 0;
              }).catch(() => {
                loggingOut += 1;
                Store.dispatch(LogoutWithSuccess());
              });
            }
            break;
          case 422:
            const errorsKeys = Object.keys(error.response.data.errors);
            if (errorsKeys.length > 0) {
              alert(error.response.data.errors[errorsKeys[0]][0]);
            }
            break;
          default:
            if (error.response.data.message) {
              alert(error.response.data.message);
            }
            break;
        }
      }
      return Promise.reject(error);
    });
    return this._axios;
  }

  public static initialize(version: string | null = null) {
    if (version) {
      this._version = version;
    }
    this._baseURI = environment.API_URL;
    this._axios = axios.create(this.settings());
    this.transformRequest();
    this.transformApiResponse();
    return this;
  }
  // {page: 1, limit: 2}
  public static urlParams(params?: Object) {
    if (typeof params !== 'object') {
      return [];
    }
    let results: Object = {};
    const paramsArr = Object.keys(params);
    if (paramsArr.length) {
      paramsArr.forEach((key: any) => {
        results = {
          ...results,
          [key]: paramsArr[key]
        };
      });
    }
    return results;
  }
  public static postWithFile(route: string, files: FileInterface[]) {
    const formData = new FormData();
    if (files) {
      for (let i: number = 0; i < files.length; i+= 1) {
        formData.append(files[i].key, files[i].file);
      }
    }
    return this._axios.post(route, { headers: { 'Content-Type': 'multipart/form-data' }, ...formData });
  }

  public static post(route: string, data?: object) {
    return this._axios.post(route, { ...data });
  }

  public static put(route: string, data?: object) {
    return this._axios.put(route, { ...data });
  }

  public static delete(route: string, data?: object) {
    return this._axios.delete(route, { ...data });
  }

  public static get(route: string, urlParams?: object) {
    return this._axios.get(route, { params: { ...this._axios.defaults.params, ...this.urlParams(urlParams) } });
  }
}
export default Api;