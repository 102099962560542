import React, { useState } from 'react';
import moment from 'moment';
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
  InfoWindow
} from "react-google-maps";

// MUI Elements
import {
  List,
  ListItem,
  ListItemIcon,
  Divider,
  ListItemText
} from '@material-ui/core';

// MUI Icons
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';

// Services
import { TSensorLogResponseValues, TLocation } from '../../../services/Hooks/Tracker';

// Config
import environment from '../../../configs/environment';

// Styles
import useStyles from './../styles/Dashboard-jss';
import { Typography } from '@material-ui/core';

const defaultLocation: TLocation = {
  latitude: 40.712122,
  longitude: -74.005600
};

const MarkerAnimation = (props: any) => {
  const [toggleMarker, setTooggleMarker] = useState<number | null>(null);

  function renderAdditionalMarkersData(marker: TSensorLogResponseValues) {
    return (
      <React.Fragment>
        <Divider />
        <List dense={true}>
          {marker.additional_markers_data?.map((itm) => (
            <ListItem disableGutters>
              <ListItemIcon>
                <NotListedLocationIcon />
              </ListItemIcon>
              <ListItemText
                primary={`Temperature: ${itm.temperature} F`}
                secondary={moment(itm.created_at).format('MM/DD/YYYY hh:mm A')}
              />
            </ListItem>
          ))}
        </List>
      </React.Fragment>
    );
  }

  return (
    <GoogleMap
      defaultZoom={10}
      center={
        props.markers.length > 0
          ? { lat: parseFloat(props.markers[props.markers.length - 1].latitude), lng: parseFloat(props.markers[props.markers.length - 1].longitude) }
          : { lat: defaultLocation.latitude, lng: defaultLocation.longitude }
        }
      zoom={18}
    >
      {props.markers.length > 0
        ? props.markers.map((marker: TSensorLogResponseValues, i: number) => {
          return (
            <Marker
              onClick={() => setTooggleMarker(marker.id)}
              key={marker.id}
              animation={2}
              position={
                marker.latitude !== undefined
                  ? { lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) }
                  : { lat: defaultLocation.latitude, lng: defaultLocation.longitude }
                }
            >
              {toggleMarker === marker.id && (
                <InfoWindow onCloseClick={() => setTooggleMarker(null)}>
                  <React.Fragment>
                    {marker.temperature !== undefined && <Typography>Temperature: <Typography display="inline" color={marker.temperature >= 90 ? "error" : "inherit"}>{marker.temperature} F</Typography></Typography>}
                    <Typography>Latitude: {marker.latitude}</Typography>
                    <Typography>Longitude: {marker.longitude}</Typography>
                    {marker.additional_markers_data !== undefined && marker.additional_markers_data.length > 0 && renderAdditionalMarkersData(marker)}
                  </React.Fragment>
                </InfoWindow>
              )}
            </Marker>
          );
        })
        : (
          <React.Fragment>
            <Marker
              animation={2}
              position={{
                lat: defaultLocation.latitude,
                lng: defaultLocation.longitude,
              }}
            />
          </React.Fragment>
          )
    }
    </GoogleMap>
  );
};

interface IProps {
  markers: TSensorLogResponseValues[];
  showMarkers: Array<number>;
};

const WrappedGoogleMap = withScriptjs(withGoogleMap(MarkerAnimation));

const GoogleMapComponent = (props: IProps) => {
  const classes = useStyles();
  return (
    <div className={classes.mapContainer}>
      <WrappedGoogleMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${environment.GOOGLE_MAPS_KEY}&v=3.exp&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        markers={props.markers}
        showMarkers={props.showMarkers}
      />
    </div>
  );
};
export default GoogleMapComponent;
